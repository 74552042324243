.widget.footer {
  position: relative;
  overflow: hidden;
  padding: 120px 0 60px;
  color: #fff;
  background: #333;
  font-size: 20px;

  &::after {
    content: '';
    width: 800px;
    height: 100%;
    background: url(../../assets/logo_bg@2x.png) 80px -40px no-repeat;
    background-size: 100%;
    position: absolute;
    right: 0;
    top: 0;
    opacity: .3;
  }

  // background: linear-gradient(to right bottom, #333, #111);

  .wrapper {
    overflow: hidden;
    // display: flex;
    // align-items: center;
    // justify-items: center;
    // align-content: center;
    // justify-content: center;
  }

  .logo {
    float: left;
    width: 120px;
    margin: 0 50px 0 0;
  }

  .text {
    // position: absolute;
    // left: 185px;
    // right: 0;
    // bottom: 0;
    float: left;
    line-height: 1.8;
    margin-top: 30px;
  }

  .copyright {
    font-size: 14px;
    color: #777;
  }
}