@import '../../styles/font.scss';

.page.plus {
  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 500px;
    // background: linear-gradient(to right bottom, #333, #000);
    background: linear-gradient(to right bottom, #E3000D, #B90610);
    padding-top: 70px;
    color: #fff;
    font-size: 20px;

    .logo {
      width: 400px;
      margin-bottom: 50px;
    }

    .btn {
      display: inline-block;
      margin-left: 10px;
      padding: 8px 16px;
      color: #B90610;
      background: #fff;
      border-radius: 6px;
      line-height: 1;
      border: none;
      font-size: 16px;
      border: 1px solid transparent;

      &.aside {
        background: transparent;
        color: #fff;
        border: 1px solid;
      }

      &:hover {
        color: #E3000D;
        background: #fff;
        border-color: transparent;
      }
    }
  }

  section {
    padding: 130px 0;
    text-align: center;

    h1 {
      font: $thinFont;
      font-size: 64px;
      margin-bottom: 60px;

      span {
        display: block;
        font-size: 20px;
        color: #ccc;
      }
    }
    p {
      margin-bottom: 30px;
    }
    .demo {
      display: block;
      margin: auto;
      transition: transform .3s ease-out;

      &:hover {
        transform: scale(1.02);
      }
    }

    &.s1 {
      // background: linear-gradient(180deg, #fff, #e0e0e0);
      .demo {
        width: 800px;
      }
    }

    &.s2 {
      background: #f5f5f5;
      b.small {
        display: block;
        font-size: 32px;
      }

      .demo {
        display: inline-block;
        img {
          width: 400px;
          vertical-align: middle;
          &.small {
            width: 350px;
            margin-right: -200px;
          }
        }
      }

      .download {
        margin-top: 60px;
      }
    }
  }
}