.widget.wrapper {
  position: relative;
  width: 1280px;
  height: 100%;
  margin: auto;
}

@media screen and (max-width: 1440px) {
  .widget.wrapper {
    width: 1190px;
  }
}

@media screen and (max-width: 1024px) {
  .widget.wrapper {
    width: auto;
    padding: 0 60px;
  }
}