@media screen and (max-width: 800px) {
  body {
    .widget.wrapper {
      padding: 0 16rpx;
    }

    .widget.header{
      height: 50rpx;
      .nav {
        >li {
          font-size: 16rpx;
          margin-right: 16rpx;
        }
        .logo {
          width: 30rpx;
        }
      }
      .account {
        .icon {
          width: 16rpx;
          margin-right: 6rpx;
        }
      }
      .language {
        .icon {
          width: 16rpx;
          margin-right: 6rpx;
        }
        .arr {
          width: 10rpx;
          height: 10rpx;
        }
      }
    }

    .widget.footer {
      padding: 40rpx 0;

      .logo {
        width: 60rpx;
        margin-right: 20rpx;
      }

      .text {
        margin-top: 5rpx;
        font-size: 18rpx;
        line-height: 1.4;
        .copyright {
          font-size: 12rpx;
        }
      }

      &::after {
        width: 400rpx;
        background-position: 100rpx 50%;
      }
    }

    .widget.download {
      a {
        margin: 0 10rpx;
        img {
          width: 160rpx;
        }
      }
    }

    .page.plus {
      .banner {
        padding-top: 50rpx;
        height: 200rpx;
        .logo {
          width: 200rpx;
        }
      }

      section {
        padding: 60rpx 0;
        
        h1 {
          margin-bottom: 40rpx;
          font-size: 36rpx;
          span {
            margin-top: 10rpx;
            font-size: 12rpx;
          }
        }

        &.s1 {
          overflow: hidden;
          .demo {
            width: 450rpx;
            margin-left: -25rpx;
          }
        }

        &.s2 {
          h1 {
            .small {
              font-size: 24rpx;
            }
          }
          .demo {
            img {
              width: 300rpx;

              &.small {
                width: 280rpx;
                margin-right: -240rpx;
              }
            }
          }
          .download {
            margin-top: 30rpx;
          }
        }
      }
    }

    .page.support {
      .banner {
        padding-top: 50rpx;
        font-size: 48rpx;
        height: 200rpx;

        h1 {
          font-size: 56rpx;
        }
      }

      section {
        padding: 60rpx 0;

        h1 {
          font-size: 32rpx;
          margin-bottom: 20rpx;
        }

        dl {
          margin: 40rpx 0;
          line-height: 1.5;
          dt {
            font-size: 24rpx;
            margin-bottom: 10rpx;
          }
          dd {
            font-size: 18rpx;
          }
        }
      }
    }

    .page.index {
      section {
        padding: 60rpx 0;
        padding-bottom: 200rpx;

        .demo {
          position: absolute;
          width: 100%;
          margin: auto;
        }
        .text {
          transition: .3s ease-out;
          h1 {
            font-size: 40rpx;
            .small {
              font-size: 18rpx;
            }
            strong {
              font-size: 50rpx;
            }
            margin-bottom: 30rpx;
          }
          p {
            line-height: 1.5;
            font-size: 14rpx;
            margin-bottom: 15rpx;
          }
          .tips {
            font-size: 12rpx;
            margin-top: -15rpx;
          }
          .download {
            margin-top: 30rpx;
            flex-direction: column;
            text-align: center;
            a {
              margin: auto auto 15rpx;
              width: 150rpx;

              .qr {
                display: none!important;
              }

              img {
                width: 100%;
              }
            }
          }
          .testflight {
            text-align: center;
            .qr {
              display: none!important;
            }
          }
        }

        &.s1 {
          padding-top: 80rpx;
          padding-bottom: 400rpx;
          .demo {
            width: 400rpx;
            bottom: -750rpx;
            left: -128rpx;
            // right: -160rpx;
            // transform: rotate(-25deg);
            z-index: 2;
          }

          &.showDemo {
            .demo {
              left: -128rpx;
              bottom: -330rpx;
              transform: rotate(0);
            }
            .text {
              opacity: .1;
            }
          }
        }

        &.s2 {
          .plusLogo {
            width: 100rpx;
            margin-bottom: 20rpx;
          }
          .text {
            min-width: auto;
            h1 {
              line-height: 1.2;
              font-size: 32rpx;
            }
          }
          .learnMore {
            text-align: right;
            .btn {
              font-size: 20rpx;
              padding: 14rpx;
              border-radius: 11rpx;
            }
          }
          .demo {
            width: 400rpx;
            bottom: -700rpx;
            right: 160rpx;
            transform: rotate(25deg);
            z-index: 2;
          }

          &.showDemo {
            .demo {
              right: 5rpx;
              bottom: -370rpx;
              transform: rotate(0);
            }
            .text {
              opacity: .1;
            }
          }
        }

        &.s3 {
          text-align: center;
          .icon {
            display: block;
            width: 120rpx;
            float: none;
            margin: auto auto 20rpx;
            position: relative;
            left: 25rpx;
          }
          .text {
            float: none;
            h1 {
              font-size: 32rpx;
              .small {
                font-size: 20rpx;
              }
            }
            .btn {
              padding: 14rpx;
              font-size: 16rpx;
              border-radius: 11rpx;
            }
          }
        }
      }
    }
  }
}