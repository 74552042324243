@import '../../styles/font.scss';

.page.index {
  // .banner {
  //   height: 500px;
  //   background: url(../../assets/banner.png) 50% 50% no-repeat;
  //   background-size: auto auto;

  //   .slogan {
  //     position: absolute;
  //     left: 0;
  //     top: 50%;
  //     width: 450px;
  //     margin-top: -80px;
  //   }
  // }

  section {
    padding: 120px 0 60px;
    font-family: $lightFont;

    h1 {
      font-family: $thinFont;
      font-size: 64px;
      margin-bottom: 35px;

      .small {
        font-size: 32px;
        line-height: 1;
      }
    }

    .text {
      overflow: hidden;
    }

    p {
      font-size: 20px;
      color: #999;
      line-height: 30px;
      margin-bottom: 15px;

      b {
        font-weight: bold;
        &.ss {
          color: #458DBA;
        }
        &.vmess {
          color: #BA459A;
        }
        &.trojan {
          color: #45BA9A;
        }
      }

      &.tips {
        font-size: 12px;
        margin-top: -20px;
      }
    }

    .demo {
      display: block;
      width: 450px;
      transition: .3s ease-out;

      &:hover {
        transform: scale(1.02);
      }
    }

    &.s1{
      padding-top: 180px;
      // background: url(../../assets/bodybg.png) 50% 0 no-repeat;
      // background-size: auto 100%;
      // box-shadow: inset 0px 0px 0px 5000px rgba(255,255,255,.3);
      // border-bottom: 1px solid #f0f0f0;
      background: linear-gradient(to right bottom, #F9FFF8, #F2FBFF);
      .text {
        float: left;
      }
      .demo {
        float: right;
        margin: -60px -45px 0 0;
      }
      .download {
        margin-top: 60px;
      }
      &::after {
        content: '';
        clear: both;
        display: block;
        height: 1px;
        overflow: hidden;
      }
    }

    &.s2 {
      overflow: hidden;
      // background: linear-gradient(90deg, #f0f0f0, #fff);
      .demo {
        float: left;
        margin: -40px 0 0 -45px;
      }
      .text {
        min-width: 700px;
        float: right;
      }
      h1 {
        font-size: 58px;
        line-height: 70px;
        strong {
          font-weight: bold;
          font-size: 68px;
        }
      }
      .plusLogo {
        width: 150px;
        display: block;
        margin-bottom: 35px;
      }
      .learnMore {
        margin-top: 150px;
        .btn {
          display: inline-block;
          font-size: 30px;
          padding: 14px;
          text-align: center;
          color: #fff;
          background: #B90610;
          border-radius: 11px;
          line-height: 1;

          &:hover {
            background: #E3000D;
          }
        }
      }
    }

    &.s3 {
      padding: 130px 0;
      // text-align: center;
      background: #f8f8f8;

      .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      h1 {
        font-size: 56px;
      }

      .icon {
        // position: absolute;
        // right: 0;
        // bottom: 0;
        // float: left;
        width: 245px;
        margin-right: 100px;
        display: block;
      }

      .btn {
        display: inline-block;
        padding: 14px;
        color: #fff;
        background: #333;
        border-radius: 11px;
        line-height: 1;
        margin-right: 30px;
        border: none;

        &:hover {
          background: #000;
        }
      }

      .link {
        color: #999;
        border-bottom: 1px solid;
        &:hover {
          color: #000;
        }
      }
    }
  }
}