.widget.download {
  display: flex;
  flex-direction: row;
  margin-left: -10px;

  a {
    margin: 0 10px;
    position: relative;

    img {
      display: block;
      width: 160px;
    }
    &[disabled] {
      cursor: default;
      opacity: .2;
      filter: grayscale(1);
    }

    .qr {
      display: none;
      position: absolute;
      bottom: 100%;
      left: 0;
      background: #fff;
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid #e0e0e0;
    }

    &:hover {
      .qr {
        display: block;
      }
    }
  }

  &.center {
    margin-left: unset;
    justify-content: center;
  }
}

.chinaTips {
  position: relative;
  font-size: 16px;
  line-height: 1.6;
  background: #ffffe1;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  margin-top: 20px;
  color: #666;

  strong {
    font-weight: bold;
    color: #DD0000;
  }
  
  a {
    text-decoration: underline;
    color: #000;
  }

  .qr {
    position: absolute;
    left: 0;
    bottom: 100%;
    padding: 10px;
    display: none;
    border: 1px solid #e0e0e0;
    background: #fff;
  }

  &:hover {
    .qr {
      display: block;
    }
  }
}