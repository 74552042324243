.widget.dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;

  .close {
    transition: .3s ease-in-out;
    svg {
      width: 32px;
      height: 32px;
      position: absolute;
      // top: -16px;
      // right: -16px;
      top: 8px;
      right: 8px;
      z-index: 2;
      fill: #fff;
    }
    &:hover {
      transform: scale(1.05);
    }
  }

  .body {
    position: relative;
    z-index: 1;
    background: #fff;
    box-shadow: 0 0 35px rgba(0,0,0,.75);
    border-radius: 12px;
    overflow: hidden;
  }

  &::after {
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,.75);
  }
}