@import '../../styles/font.scss';

.page.support {
  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 350px;
    background: linear-gradient(to right bottom, #F9FFF8, #F2FBFF);
    padding-top: 70px;

    h1 {
      font-family: $thinFont;
      font-size: 78px;
    }

    border-bottom: 1px solid #f0f0f0;
  }

  section {
    padding: 130px 0;

    h1 {
      font: $thinFont;
      font-size: 48px;
      margin-bottom: 60px;

      span {
        display: block;
        font-size: 20px;
        color: #ccc;
      }
    }

    dl {
      line-height: 1.8;
      margin: 50px 0;
      dt {
        font-size: 32px;
      }
      dd {
        font-size: 20px;
        color: #999;
      }
    }
  }
}