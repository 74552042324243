@import '../../styles/font.scss';
.page.providers {
  font-family: $baseFont;
  background: #F8F8F8;
  min-height: 100vh;
  font-size: 16rpx;
  display: flex;
  flex-direction: column;

  ul {
    flex: 1;
  }

  .tag {
    display: inline-block;
    vertical-align: 4rpx;
    font-size: 12rpx;
    line-height: 20rpx;
    padding: 0 8rpx;
    border-radius: 10rpx;

    &.sale {
      background-color: #DD0000;
      color: #fff;
    }
  }

  .provider {
    padding: 22rpx;
    border-bottom: 1px solid #f0f0f0;
    background: #fff;
    display: flex;
    align-items: center;

    .icon {
      float: left;
      width: 64rpx;
      height: 64rpx;
      margin-right: 20rpx;
      border: 1px solid #f0f0f0;
      border-radius: 8rpx;
      background: #fff;
      overflow: hidden;
      padding: 8rpx;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      img {
        display: block;
        width: 100%;
      }
    }
    dl {
      dt {
        font-size: 24rpx;
        margin-bottom: 4rpx;
        color: #333;
      }
      dd {
        font-size: 14rpx;
        line-height: 20rpx;
        color: #999;
      }
    }
  }
  .fold {
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    text-align: center;
    padding: 16rpx 0;
    img {
      width: 24rpx;
      vertical-align: -7rpx;
    }
  }
  .biz {
    padding: 32rpx 0;
    text-align: center;
    color: #aaa;
    font-size: 12rpx;

    strong {
      display: block;
    }

    a {
      color: #aaa;
      text-decoration: underline;
    }
  }
}