@media screen and (max-width: 1280px) {
  body {
    .widget.footer {
      padding: 60px 0;
    }
  
    .page.index {
      section {
        padding: 60px 0;
        overflow: hidden;
  
        .text,
        .demo {
          float: none!important;
        }
  
        .text {
          margin: 0;
          position: relative;
          z-index: 1;
          // transform: scale(.85);
          transform-origin: 0 0;
        }
        .demo {
          // transform: rotate(15deg);
          right: -120px;
          bottom: -280px;
          margin: auto!important;
          display: block;
          position: absolute;
  
          &:hover {
            transform: rotate(0);
          }
        }
  
        &.s1 {
          padding-top: 100px;
        }
  
        &.s2 {
          .learnMore {
            margin-top: 50px;
          }
        }
  
        &.s3 {
          padding: 60px 0;
  
          .text {
            h1 {
              font-size: 48px;
            }
            .small {
              font-size: 28px;
            }
          }
  
          .wrapper {
            display: block;
          }
  
          .icon {
            float: left;
            width: 220px;
            margin-right: 50px;
            // position: absolute;
            // left: -60px;
            // top: 0;
          }
        }
      }
    }
  }
}