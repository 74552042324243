@import './font.scss';
@import './adapter_pad.scss';
@import './adapter_phone.scss';

body {
  font-family: $lightFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  font-weight: normal;
}

li {
  list-style: none;
}

body,
a {
  color: #333;
}

a {
  text-decoration: none;
  &:hover {
    color: #000;
    fill: #000;
  }
}

svg {
  display: block;
  width: 100%;
  height: 100%;
}

button {
  cursor: pointer;
}