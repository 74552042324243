.widget.login {
  width: 400px;
  padding: 30px;

  h1 {
    color: #fff;
    padding: 30px;
    margin: -30px -30px 30px;
    font-size: 36px;
    background: linear-gradient(to right bottom, #E3000D, #B90610) no-repeat;
    text-align: center;

    img {
      width: 75px;
      display: block;
      margin: auto;
      margin-top: -10px;
      margin-bottom: 20px;
    }
  }

  form {
    margin-top: 30px;
    li {
      margin-bottom: 20px;
      label {
        display: block;
        color: #999;
        transition: .2s ease-out;
        line-height: 1;
      }
      input {
        box-sizing: border-box;
        width: 100%;
        font-size: 24px;
        padding: 6px 0;
        border-bottom: 1px solid #ccc;
        background: #fff;
        outline: none;
        transition: .2s ease-out;
      }

      &:focus-within {
        label {
          color: #333;
        }
        input {
          border-color: #333;
        }
      }
    }
    button {
      transition: .3s ease-in-out;
      margin-top: 100px;
      box-sizing: border-box;
      width: 100%;
      padding: 14px;
      font-size: 24px;
      border-radius: 11px;
      background: #333;
      color: #fff;

      &:hover {
        background: #000;
      }
    }
  }

  .change {
    margin-top: 15px;
    text-align: center;
    a {
      font-size: 14px;
      border-bottom: 1px solid;
    }
  }

  .reg {
    font-size: 18px;
    form {
      margin-top: 80px;
      p {
        margin-bottom: 20px;
      }
    }
    .download {
      margin-bottom: 120px;
      img {
        width: 120px;
      }
    }
  }
}