@import '../../styles/font.scss';

.widget.header {
  $height: 65px;

  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  right: 0;
  background: rgba(240, 240, 240, .35);
  height: $height;
  backdrop-filter: saturate(200%) blur(5px);
  border-bottom: 1px rgba(0, 0, 0, .05) solid;

  .nav {
    float: left;
    height: 100%;
    font-family: $baseFont;
    font-size: 20px;
    line-height: 1;

    >li {
      float: left;
      height: 100%;
      position: relative;
      margin-right: 50px;

      a {
        display: flex;
        align-items: center;
        justify-items: center;
        position: relative;
        height: 100%;

        &.active {
          &::after {
            content: '';
            height: 3px;
            background: #000;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
          }
        }
      }

      sup {
        position: relative;
        top: -5px;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    &.extra{
      float: right;
      font-size: 16px;

      .icon {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .language {
    .arr {
      width: 8px;
      height: 8px;
      margin: -2px 0 0 5px;
    }
    &:hover {
      .menu {
        visibility: visible;
      }
    }
  }

  .menu {
    visibility: hidden;
    position: absolute;
    top: 50px;
    right: 0;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 15px rgba(0, 0, 0, .15);

    li {
      font-size: 18px;
      min-width: 120px;
      padding: 12px;
      cursor: pointer;
      &:hover {
        background: #333;
        color: #fff;
      }
    }
  }

  .logo {
    display: block;
    width: 50px;
  }

  &.dark {
    background: rgba($color: #000, $alpha: .85);
    border: none;

    .nav {
      a {
        color: #fff;

        &.active {
          &::after {
            background: #fff;
          }
        }
      }
      .arr,
      .icon {
        fill: #fff;
      }
    }
  }
}